import React from 'react'
import {Link} from 'react-router-dom'

const Homepage = () => {
    return (
        <div className='container mx-2'>
            <div className='container mt-3'>
                <h3>La nostra visione</h3>
                <p className='fs-6 mt-2 p-3'>
                    Territ API rappresenta <span className='evidence fw-bold'> l'unico servizio italiano  </span>
                    in grado di fornire <u> informazioni dettagliate </u> su <span className='evidence fw-bold'> comuni, 
                    province e regioni </span> attraverso un'API dedicata. 
                    Grazie al nostro <u>sistema di aggiornamento automatico</u>, 
                    i dati sono sempre precisi e aggiornati. 
                    In <span className='evidence fw-bold'> pochi istanti </span>, i tuoi clienti potranno accedere 
                    all' <u> elenco completo </u> di comuni, province e regioni, 
                    arricchito da <span className='evidence fw-bold'> informazioni dettagliate </span>.
                    <br /> <br />
                    <span className='fst-italic fw-bold'> Stai utilizzando una form per visualizzare gli elenchi dei comuni,
                    province e regioni? </span> In tal caso, possiamo aiutarti a <u> ottimizzare 
                    l'esperienza degli utenti </u> e migliorare la velocità di caricamento
                    utilizzando la nostra API leggera.
                </p>
            </div>
            <hr className='col-sm-12' style={{ margin: '5px 0' }} />
            <div className='container mt-3'>
                <h3>Ottieni una chiave API</h3>
                <p className='fs-6 mt-2 p-3'>
                    L'API di Territ offre la possibilità di <span className='evidence fw-bold'> personalizzazione avanzata </span>, 
                    consentendo di <u> filtrare le informazioni </u> per comuni, province e regioni, 
                    con una vasta gamma di dettagli disponibili. <br />
                    <div className='pt-2'>
                        <span className='fw-bold text-danger'>-</span> <span className='fst-italic fw-bold'> Hai la necessità di visualizzare tutte le province di una specifica
                        regione? <br /> </span>
                        <span className='fw-bold text-danger'>-</span> <span className='fst-italic fw-bold'> Oppure desideri consultare l'elenco completo dei comuni di una provincia? <br /> </span>
                        <span className='fw-bold text-danger'>-</span> <span className='fst-italic fw-bold'> O magari ti interessa accedere alle informazioni dettagliate 
                        su ogni singolo comune, provincia e regione? </span>
                    </div> 
                    <br />
                    Con la nostra <u> API </u> <span className='evidence fw-bold'> tutto ciò è possibile. </span>
                    Offriamo <span className='fw-bold'> dati sempre aggiornati </span> con tempi di risposta rapidi, 
                    garantendo un' <u> esperienza efficiente  e completa </u> per te e i tuoi utenti. 
                    <br /> <br />
                    <p>
                        {/* Link che riportano a RapidAPI dove verrà caricata una
                         * chiave con accesso publico limitata alla prova di 7/10gg
                         * mentre il secondo link è per l'uso con chiamate a pagamento
                        */}
                        <a className='evidence_a' href='/'>Provaci oggi</a>
                        <span> o </span>
                        <Link className='evidence_a' to='/register'>registrati gratuitamente</Link>
                    </p>
                </p>
            </div>

        </div>
    )
}

export default Homepage