import React from 'react'
import { Link } from 'react-router-dom'

const HomeDoc = () => {
    return (
        <div className='container mx-2'>
            <h2 className='fw-bold'>Territ API</h2>
            <hr className='col-sm-12' style={{ margin: '5px 0' }} />
            <div className='container mt-3'>
                <p className='fs-6'>
                    Benvenuti nella 
                    <span className='fw-bold'> <u> documentazione ufficiale </u> </span> di 
                    <span className='evidence fw-bold'> Territ API. </span> 
                    Qui troverai tutte le 
                    <span className='fw-bold'> informazioni necessarie</span> per 
                    <u> integrare
                    e utilizzare</u>  con successo i 
                    <span className='fw-bold'> nostri servizi API </span> per accedere 
                    ai <span className='fw-bold evidence'> dati </span> 
                    <span className='fw-bold'> relativi alle regioni, province e comuni</span>
                    <span className='evidence fw-bold'> Italiani</span>.
                </p> 

                <hr className='col-sm-12' style={{ margin: '5px 0' }} /> <br />
                <h3>Cos'è Territ API ?</h3>
                <p className='fs-6 mt-2'>
                    <br />
                    Territ API è un <u className='fw-bold'> servizio API </u> 
                    che fornisce 
                    <span className='fw-bold evidence'> informazioni dettagliate </span> 
                    sulle regioni, province e comuni Italiani. 
                    Con Territ API, puoi 
                    <span className='fw-bold'> accedere facilmente </span> 
                    a <u>dati aggiornati e precisi </u>  
                    per <span className='fw-bold'>facilitare</span> 
                    <span className='evidence fw-bold'> lo sviluppo di applicazioni </span> 
                    e servizi che richiedono
                    <span className='fw-bold'> <u>informazioni geografiche dettagliate</u> </span>. <br /> <br />
                </p>

                <h3>Come Utilizzare l'API</h3>
                <p className='fs-6 mt-2'>
                    <br />
                    Per <u>iniziare a utilizzare</u> Territ API, 
                    è <span className='fw-bold'>necessario ottenere </span> 
                    una <Link to={'/doc/auth'} className='fw-bold evidence_a'><u>chiave API valida </u> </Link> 
                    facendo l'accesso alla <Link to={'/login'} className='fw-bold evidence_a'><u>dashboard</u></Link>.
                    Una volta ottenuta la 
                    chiave API, puoi 
                    <span className='fw-bold'> iniziare a fare richieste </span> 
                    agli <span className='fw-bold evidence'>endpoint disponibili </span>
                    per <u>accedere ai dati delle regioni, province e comuni</u>. <br /> <br />
                </p>

                <h3>Endpoint Disponibili</h3>
                <ul className="list mx-auto p-2 lh-base">
                    <li className="list-group-item">
                        <code>/regioni </code> -{'>'} Ottieni un elenco completo delle regioni Italiane.
                    </li>
                    <li className="list-group-item">
                        <code>/province </code> -{'>'} Ottieni un elenco completo delle province Italiane.
                    </li>
                    <li className="list-group-item ms-3">
                        <code>/province/:regione </code> -{'>'} Ottieni un elenco di province che 
                        appartengono a una regione specifica.
                    </li>
                    <li className="list-group-item">
                        <code>/comuni </code> -{'>'} Ottieni un elenco completo dei comuni Italiani.
                    </li>
                    <li className="list-group-item ms-3">
                        <code>/comuni/:regione </code> -{'>'} Ottieni un elenco di comuni che 
                        appartengono a una regione specifica.
                    </li>
                    <li className="list-group-item ms-3">
                        <code>/comuni/provincia/:provincia </code> -{'>'} Ottieni un elenco di 
                        comuni che fanno parte di una provincia specifica.
                    </li>
                </ul>
                <p className='fs-6 mt-2'>
                    Per <span className='fw-bold evidence'>informazioni dettagliate </span> 
                    su come utilizzare ciascun endpoint, 
                    <span className='fw-bold'> consulta la sezione </span>
                    <Link to={'/doc/endpoints'} className='evidence_a fw-bold'><u>Endpoints</u></Link>. <br /><br />
                </p>

                <h3>Documentazione Dettagliata</h3>
                <p className='fs-6 mt-2'>
                    <br />
                    Per <u>informazioni più dettagliate </u> sull'utilizzo 
                    dei singoli endpoint e per accedere alla nostra 
                    <span className='fw-bold evidence'> guida completa </span>, 
                    <span className='fw-bold'> consulta la sezione </span>
                    <Link to={'/doc/config'} className='evidence_a fw-bold'> <u>Configurazione</u></Link>. <br /><br />
                </p>

                <h3>Supporto</h3>
                <p className='fs-6 mt-2'>
                    <br />
                    Per <span className='fw-bold'>assistenza</span>, 
                    <span className='fw-bold'> domande </span> o 
                    <span className='fw-bold'> problemi relativi all'utilizzo dell'API </span>, 
                    <u> non esitare a contattare </u>
                    il nostro  <span className='fw-bold evidence'> team di supporto all'indirizzo </span>
                    <u className='fst-italic'> info.territapi@gmail.com </u>
                    o tramite il nostro 
                    <Link to={'/doc/support'} className='fw-bold evidence_a'> <u>modulo di contatto</u></Link>. <br />
                </p>

                <h6 className='mt-5 text-center fst-italic'>
                    Grazie per aver scelto <span className='evidence'>Territ API </span>
                    per le tue esigenze d' 
                    <span className='evidence'>integrazione geografica</span>!
                </h6>
            </div>
        </div>

        
    )
}

export default HomeDoc