import React from 'react'
import { Link } from 'react-router-dom'

const Authpage = () => {
  return (
    <div className='container mx-2'>
      <h2 className='fw-bold'>Autenticarsi</h2>

      <div className='container mt-3'>
        <h5>
          Come posso autenticarmi per utilizzare l'API ?
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-2'>
          <br />
            Per accedere ai nostri <Link className='evidence_a fw-bold' to={'/doc/endpoints'}> Enpoints </Link>
            API, è necessario autenticarsi. L'autenticazione avviene in 2 semplicissimi passi.
          <br />
        </p>
      </div>

      <div className='container mt-4'>
        <h5>
          Registrazione/Login
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-1'>
          <br />
            Per accedere alla <span className='evidence'>Dashboard</span> è necessario 
            registrarsi. Per farlo clicca su <Link className='evidence_a fw-bold' to={"/register"}> Registrati.</Link> <br />
            Durante la registrazione, ti chiederemo il tuo nome, cognome, username/email e password. <br /> 
            Se hai già un account, puoi effettuare il login cliccando su  <Link className='evidence_a fw-bold' to={"/login"}> login. </Link> 
            Una volta dentro la dashboard, potrai visualizzare l'<u>API Key</u>.
          <br />
        </p>
      </div>

      <div className='container mt-4'>
        <h5>
          Come utilizzo la chiave API ?
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-1'>
          <br />
           L'API Key è una chiave univoca che devi inserire nell'header 'x-api-key'
           di ogni richiesta API che fai. <br />
           Assicurati di configurare correttamente l'API, puoi farlo nella sezione 
           di <Link className='evidence_a fw-bold' to={"/doc/config"}> Configurazione</Link>. 
          <br />
          <br />
        </p>
      </div>

      <div className='container mt-4'>
        <h5>
          Pronto per iniziare ? Effettua il login e inizia a esplorare le potenzialità
          della nostra API per integrarla nei tuoi progetti.
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-1'>
          <br />
            Se hai domande o bisogno di assistenza ti preghiamo di 
            <Link className='evidence_a fw-bold' to={"/doc/support"}> Contattarci</Link>.
          <br />
          <br />
        </p>
      </div>

    </div>
  )
}

export default Authpage