import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Footer from './components/Footer';
import Documentation from "./components/Documentation";
import { Route, Routes, useLocation } from 'react-router-dom';
import PageNotFound from "./pages/PageNotFound";
import Login from "./pages/Login";
import Dashboard from "./area-privata/Dashboard";
import Register from "./pages/Register";
import Authpage from "./pages/Authpage";
import Endpointspages from "./pages/Endpointspages";
import ConfigurazionePage from "./pages/ConfigurazionePage";
import SupportoPage from './pages/SupportoPage';

function App() {

  const location = useLocation();
  const hideNavbarRoutes = ['/login', '/register']; // Aggiungi altri percorsi se necessario
  const hideFooterRoutes = ['/login', '/register'];

  return (
    <div>
      {/* Mostra la Navbar solo se il percorso attuale non è in `hideNavbarRoutes` */}
      {!hideNavbarRoutes.includes(location.pathname) && <Navbar />}
      
      <Routes>
        <Route path='/' element={<Main />} />

        {/* PAGINE PUBBLICHE */}
        <Route path='doc' element={<Documentation />}>
          {/* subrouting di doc */}
          <Route path='config' element={<ConfigurazionePage />} />
          <Route path='auth' element={<Authpage />} />
          <Route path='endpoints' element={<Endpointspages />} />
          <Route path='support' element={<SupportoPage />} />
        </Route>

        {/* AUTH */}
        <Route path='/login' element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* AREA PRIVATA */}
        <Route path="/area-privata" element={<Dashboard />}> 
          {/* subrouting di area privata */}
        </Route>

        <Route path='*' element={<PageNotFound />} />
      </Routes>

      {/* Mostra il Footer solo se il percorso attuale non è in `hideNavbarRoutes` */}
      {!hideFooterRoutes.includes(location.pathname) && <Footer />}
      
    </div>
  );
}

export default App;
