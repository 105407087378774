import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Importa il tema 'dark'

// Esempi di codice per diversi linguaggi
const codeExamples = {
  javascript: `
    const axios = require('axios');
    const territURL = 'https://api.territ.it';

    axios.get(territURL + '/endpoint', {
      headers: {
          'x-api-key': 'la_tua_chiave_api'
      }
    })
    .then(response => {
        console.log('Risposta Territ API:', response.data);
    })
    .catch(error => {
        console.error('Errore Territ API:', error.response.data);
    });
  `,
  python: `
    import requests

    territ_url = 'https://api.territ.it/endpoint'
    headers = {'x-api-key': 'la_tua_chiave_api'}

    response = requests.get(territ_url, headers=headers)

    if response.status_code == 200:
        print('Risposta Territ API:', response.json())
    else:
        print('Errore Territ API:', response.json())
  `,
  ruby: `
    require 'net/http'
    require 'uri'
    require 'json'

    uri = URI.parse('https://api.territ.it/endpoint')
    request = Net::HTTP::Get.new(uri)
    request['x-api-key'] = 'la_tua_chiave_api'

    response = Net::HTTP.start(uri.hostname, uri.port, use_ssl: uri.scheme == 'https') do |http|
      http.request(request)
    end

    if response.code.to_i == 200
      puts 'Risposta Territ API:', JSON.parse(response.body)
    else
      puts 'Errore Territ API:', JSON.parse(response.body)
    end
  `,
  php: `
    <?php

    $url = 'https://api.territ.it/endpoint';

    $options = array(
      'http' => array(
        'header'  => "x-api-key: la_tua_chiave_api",
        'method'  => 'GET'
      )
    );

    $context  = stream_context_create($options);
    $result = file_get_contents($url, false, $context);

    if ($result === FALSE) {
      die('Errore Territ API');
    }

    echo 'Risposta Territ API:', $result;
    ?>
  `,
  java: `
    import java.io.BufferedReader;
    import java.io.InputStreamReader;
    import java.net.HttpURLConnection;
    import java.net.URL;

    public class Main {
        public static void main(String[] args) {
            try {
                String url = "https://api.territ.it/endpoint";
                URL obj = new URL(url);
                HttpURLConnection con = (HttpURLConnection) obj.openConnection();
                con.setRequestMethod("GET");
                con.setRequestProperty("x-api-key", "la_tua_chiave_api");

                BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));
                String inputLine;
                StringBuilder response = new StringBuilder();

                while ((inputLine = in.readLine()) != null) {
                    response.append(inputLine);
                }
                in.close();

                System.out.println("Risposta Territ API: " + response.toString());
            } catch (Exception e) {
                e.printStackTrace();
            }
        }
    }
  `,
  csharp: `
    using System;
    using System.Net.Http;
    using System.Threading.Tasks;

    class Program
    {
        static async Task Main(string[] args)
        {
            var client = new HttpClient();
            client.DefaultRequestHeaders.Add("x-api-key", "la_tua_chiave_api");

            var response = await client.GetStringAsync("https://api.territ.it/endpoint");

            Console.WriteLine("Risposta Territ API: " + response);
        }
    }
  `,
  go: `
    package main

    import (
        "fmt"
        "io/ioutil"
        "net/http"
    )

    func main() {
        resp, err := http.Get("https://api.territ.it/endpoint")
        if err != nil {
            fmt.Println("Errore Territ API:", err)
            return
        }
        defer resp.Body.Close()

        body, err := ioutil.ReadAll(resp.Body)
        if err != nil {
            fmt.Println("Errore durante la lettura della risposta:", err)
            return
        }

        fmt.Println("Risposta Territ API:", string(body))
    }
  `,
  swift: `
    import Foundation

    let url = URL(string: "https://api.territ.it/endpoint")!
    var request = URLRequest(url: url)
    request.setValue("la_tua_chiave_api", forHTTPHeaderField: "x-api-key")

    let task = URLSession.shared.dataTask(with: request) { data, response, error in
        if let error = error {
            print("Errore Territ API:", error)
            return
        }

        if let data = data, let responseString = String(data: data, encoding: .utf8) {
            print("Risposta Territ API:", responseString)
        }
    }

    task.resume()
  `,
  kotlin: `
    import java.net.HttpURLConnection
    import java.net.URL

    fun main() {
        val url = URL("https://api.territ.it/endpoint")
        with(url.openConnection() as HttpURLConnection) {
            requestMethod = "GET"
            setRequestProperty("x-api-key", "la_tua_chiave_api")

            val response = inputStream.bufferedReader().use { it.readText() }
            println("Risposta Territ API: $response")
        }
    }
  `,
  rust: `
    use reqwest::header::HeaderMap;
    use reqwest::Client;
    use std::error::Error;

    #[tokio::main]
    async fn main() -> Result<(), Box<dyn Error>> {
        let url = "https://api.territ.it/endpoint";
        let mut headers = HeaderMap::new();
        headers.insert("x-api-key", "la_tua_chiave_api".parse().unwrap());

        let client = Client::new();
        let res = client.get(url).headers(headers).send().await?;
        let body = res.text().await?;

        println!("Risposta Territ API: {}", body);

        Ok(())
    }
  `,
  shell: `
    curl -H "x-api-key: la_tua_chiave_api" https://api.territ.it/endpoint
  `,
  scala: `
    import scala.io.Source
    import java.net.HttpURLConnection
    import java.net.URL

    object Main extends App {
        val url = new URL("https://api.territ.it/endpoint")
        val connection = url.openConnection().asInstanceOf[HttpURLConnection]
        connection.setRequestMethod("GET")
        connection.setRequestProperty("x-api-key", "la_tua_chiave_api")

        val response = Source.fromInputStream(connection.getInputStream).mkString
        println(s"Risposta Territ API: $response")
    }
  `,
  typescript: `
    import axios from 'axios';

    const territURL = 'https://api.territ.it/endpoint';

    axios.get(territURL, {
      headers: {
          'x-api-key': 'la_tua_chiave_api'
      }
    })
    .then(response => {
        console.log('Risposta Territ API:', response.data);
    })
    .catch(error => {
        console.error('Errore Territ API:', error.response.data);
    });
  `,
  html: `
    <script>
      fetch('https://api.territ.it/endpoint', {
        headers: {
          'x-api-key': 'la_tua_chiave_api'
        }
      })
      .then(response => response.text())
      .then(data => {
        console.log('Risposta Territ API:', data);
      })
      .catch(error => {
        console.error('Errore Territ API:', error);
      });
    </script>
  `,
  lua: `
    local http = require("socket.http")
    local ltn12 = require("ltn12")

    local response = {}
    local res, code, response_headers = http.request{
        url = "https://api.territ.it/endpoint",
        headers = { ["x-api-key"] = "la_tua_chiave_api" },
        sink = ltn12.sink.table(response)
    }

    if code == 200 then
        print("Risposta Territ API:", table.concat(response))
    else
        print("Errore Territ API:", code)
    end
  `,
  perl: `
    use LWP::UserAgent;

    my $url = 'https://api.territ.it/endpoint';
    my $ua = LWP::UserAgent->new;
    my $response = $ua->get($url, 'x-api-key' => 'la_tua_chiave_api');

    if ($response->is_success) {
        print "Risposta Territ API: ", $response->decoded_content;
    } else {
        die "Errore Territ API: ", $response->status_line;
    }
  `,
  dart: `
    import 'package:http/http.dart' as http;

    void main() async {
      final response = await http.get(
        Uri.parse('https://api.territ.it/endpoint'),
        headers: {
          'x-api-key': 'la_tua_chiave_api',
        },
      );

      if (response.statusCode == 200) {
        print('Risposta Territ API: $ {response.body}');
      } else {
        print('Errore Territ API: $ {response.statusCode}');
      }
    }
  `,
  clojure: `
    (ns example
      (:require [clj-http.client :as client]))

    (defn fetch-data []
      (let [response (client/get "https://api.territ.it/endpoint"
                                 {:headers {"x-api-key" "la_tua_chiave_api"}})]
        (println "Risposta Territ API:" (:body response))))
  `,
  R: `
    library(httr)

    url <- "https://api.territ.it/endpoint"
    response <- GET(url, add_headers("x-api-key" = "la_tua_chiave_api"))

    if (status_code(response) == 200) {
      content <- content(response, "text")
      cat("Risposta Territ API:", content)
    } else {
      cat("Errore Territ API:", status_code(response))
    }
  `,
};

const ConfigurazionePage = () => {
  const [language, setLanguage] = useState('javascript');
  const [code, setCode] = useState(codeExamples['javascript']);

  const handleChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    setCode(codeExamples[selectedLanguage]);
  };

  return (
    <div className='container mx-2'>
      <h2 className='fw-bold'>Configurare Territ API</h2>

      <div className='container mt-3'>
        <h5>Benvenuto nella configurazione dell'API</h5>
        <hr className='col-sm-9' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-2'>
          <br />
          Per utilizzare l'API, è necessario
          <a href='/doc/auth'> autenticarsi </a>
          utilizzando una chiave API fornita dal nostro sistema.
          La chiave API deve essere inclusa nell' <u>header</u>
          <span className='fw-bold'> x-api-key </span>
          di ogni richiesta HTTP.
          <br />
        </p>
      </div>

      <div className='container mt-4'>
        <select value={language} onChange={handleChange} className='form-select mb-3'>
          {Object.keys(codeExamples).map((lang) => (
            <option key={lang} value={lang}>
              {lang.charAt(0).toUpperCase() + lang.slice(1)}
            </option>
          ))}
        </select>
        <SyntaxHighlighter language={language} style={a11yDark}>
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default ConfigurazionePage;
