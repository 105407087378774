import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className='container'>
      <h1 className='fw-bold mt-5 text-center'>404 Page Not Found</h1>
      <h4 className='text-dark-50 fst-italic fs-5 text-center'>Pagina non trovata, si prega di ritornare alla home</h4>
      <div className='text-center'>
        <Link className='text-center btn btn_evidence text-white' to='/' >Home</Link>
      </div>
    </div>
  )
}

export default PageNotFound