import { React, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Widget from '../components/Widget';
import axios  from 'axios';
import Alert from '../components/Alert';

const apiUrl = process.env.REACT_APP_API_URL || 'https://api.territ.it';

const Dashboard = () => {
    //recupero variabile di sessione
    const isLoggedIn = localStorage.getItem('isLogin');
    //const token = localStorage.getItem('tokenAuth');
    //const username = localStorage.getItem('username');
   
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [abbonamento, setAbbonamento] = useState('');
    const [c_disponibili, setCDisponibili] = useState('');
    const [c_residue, setCRedisue] = useState('');
    const [c_generate, setCGenerate] = useState('');
    const [usage, setUsage] = useState('');
    const [token, setToken] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [privilegi, setPrivilegi] = useState(null);

    const [t_ut, setTut] = useState(null);
    const [t_abb, setTabb] = useState(null);
    const [t_call, setTcall] = useState(null);
    const [automation_sis, setAutomationSi] = useState(null);

    const key = "Ng!en6_p!$IEgiCq=e-R9";
    const kk = "3a7f1d8e4b9c0a2f6e5d4b7c8a1f3e2d";

    //per aggiornare il token dopo il cambiamento
    useEffect(() => {
        // Inizializza il valore di tokenAuth quando la pagina viene caricata
        const storedToken = localStorage.getItem('tokenAuth');
        if (storedToken) {
            setToken(storedToken);
        }
    }, []); // L'array vuoto come secondo argomento indica che l'effetto deve essere eseguito solo una volta, quando il componente viene montato

    //genero una nuova chiave quando richiesta
    const handleGenerateApiKey = () => {
        const userId = localStorage.getItem('userId');
        setIsLoading(true);
        axios.post(apiUrl+`/auth/generateKey?UID=${userId}`)
            .then(res => {
                setToken(res.data.newApiKey);
                localStorage.setItem('tokenAuth', res.data.newApiKey);
                setIsLoading(false);
                setCGenerate(res.data.totalChiaviGenerate);
            })
            .catch(error => {
                console.error('Errore durante la generazione della nuova chiave API:', error);
                setIsLoading(false);
            });
    };

    //utilizzo una chiamata api al server per avere più informazioni sull'utente loggato
    useEffect(() => {
        const fetchData = async ()=>{
            try{
                const userId = localStorage.getItem('userId');
                const url =apiUrl+`/auth/user/${userId}?PR_KEY=${key}`; 
                axios.get(url).then(res => {
                    //console.log(res.data);
                    const nome=res.data.info.nome;
                    const cognome=res.data.info.cognome;
                    const username = `${nome} ${cognome}`;
                    const priv = res.data.info.privilegi;
                    const abbonamento = res.data.info.abbonamento;
                    const c_disponibili = res.data.info.chiamateRimanenti;
                    const c_giornaliere = res.data.info.chiamateGiornaliere;
                    const c_generate = res.data.info.chiaviGenerate;
                    if(abbonamento === 'free/base'){
                        setAbbonamento('Gratuito');
                    }
                    if(c_disponibili){
                        for (let percentuale = 10; percentuale <= 100; percentuale += 10) {
                            let percentualeCalcolata = calcolaPercentuale(c_disponibili, c_giornaliere);
                            //console.log(percentuale + "% del totale corrisponde a " + percentualeCalcolata.toFixed(2));
                            let uso = percentualeCalcolata;
                            //console.log(uso)
                            setUsage(uso+"%");
                        }
                    }else{
                        setUsage('Chiamate Giornaliere Terminate');
                    }
                    //setAbbonamento(abbonamento);
                    setUsername(username);
                    setCDisponibili(c_giornaliere);
                    setCRedisue(c_disponibili);
                    setCGenerate(c_generate);
                    setPrivilegi(priv);
                    localStorage.setItem('ppvt', priv);
                })

            }catch (error) {
                console.error('Errore nel recupero delle informazioni utente:', error);
            }
        }

        const timeout = setTimeout(() => {
            fetchData();
        }, 20000); // 20 secondi

        fetchData();

        return () => clearTimeout(timeout);

    }, []);

    //per avere info Admin
    useEffect(() => {
        const privi= localStorage.getItem('ppvt');
        const dataByP = () => {
            axios.get(apiUrl+`/info/vDk8LnqzRT5P6G12QaW3s4XcYbE9F0p7/${privi}/${kk}`)
                .then(res => {
                    //console.log(res);
                    const t_utenti = res.data.data[0].totale;
                    const t_abbonamenti = res.data.data[1].totale;
                    const t_chiamate = res.data.data[2].totale;
                    const sistema_automatico = res.data.data[3];
                    setTut(t_utenti);
                    setTabb(t_abbonamenti);
                    setTcall(t_chiamate);
                    setAutomationSi(sistema_automatico);
                })
                .catch(error => {
                    //console.log('va bene');
                    console.log('ok-p');
                });
        };

        const timeout = setTimeout(() => {
            dataByP();
        }, 20000); // 20 secondi
        
        dataByP();

        return () => clearTimeout(timeout);
    }, []);

    //funzione per calcolare percentuale
    function calcolaPercentuale(valore, totale){
        return (valore*100)/totale;
    }

    //funzione per copiare il token tramite bottone
    const handleCopyToken = () =>{
        navigator.clipboard.writeText(token)
        .then(() => {
            setAlertMessage('Testo copiato negli appunti!');
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000); // Nascondi l'alert dopo 3 secondi
        })
        .catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }

    return (
        <>
            {/* Utilizza la logica di rendering condizionale per mostrare il componente protetto dopo il login */}
            {isLoggedIn ? (
                <div className='container-fluid ps-2'>
                    <div className='row mt-2'>
                        <div className='col-12 col-sm-10'>
                            <h2 className='mt-2'>Dashboard</h2>
                        </div>
                        <div className='col-12 col-sm-2 border-dark border border-2 rounded-start'>
                            <p className='mt-3 fw-bold fs-6 cursor-p'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="currentColor" className="bi bi-person" viewBox="2 0 16 17">
                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                            </svg> 
                                {username}
                            </p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        {/* ########## ALERT ##########  */}
                        <Alert message={alertMessage} show={showAlert} />
                        {/* Widget 1 */}
                        <Widget
                            title='Chiamata Disponibili Giornaliere'
                            maxCall={c_disponibili+"/d"}
                        />
                        {/* Widget 2 */}
                        <Widget
                            title='Chiamata Residue Giornaliere'
                            maxCall={c_residue}
                            uso={usage}
                        />
                        {/* Widget 3 */}
                        <Widget
                            title="Piano D'abbonamento"
                            maxCall={abbonamento}
                            //uso="Gratuito"
                            text="Scadenza: 24-12-2024"
                        />
                        {/* Widget 4 */}
                        <Widget
                            title='API-Key Generate'
                            maxCall={c_generate}
                            text="per effettuare le chiamate"
                        />
                        {/* ########## WIDGET PER GLI ADMIN ########## */}
                        {privilegi === 7 && (
                            <>
                                <Widget
                                    title='Utenti Registrati'
                                    maxCall={t_ut}
                                    text=""
                                />
                                <Widget
                                    title='Chiamate totali fatte'
                                    maxCall={t_call}
                                    text=""
                                />
                                <Widget
                                    title='Sistema automatico aggiornato il'
                                    maxCall={automation_sis}
                                    text=""
                                />
                                <Widget
                                    title='Abbonamenti sottoscritti'
                                    maxCall={t_abb}
                                    text=""
                                />
                            </>
                        )}
                    </div>

                    {/* MAIN */}
                    <div className='text-center mt-5 '>
                        <p className='text-muted mt-2 fst-italic'> 
                            <span className='fst-normal fw-bold'>
                                API-Key:
                            </span> 
                            {/* token recuperato */}
                            {token} 
                            <span className='mx-2'>
                                <button className='btn btn-outline-success fw-bold' onClick={handleCopyToken}>Copy</button>
                            </span>
                        </p>

                        <button onClick={handleGenerateApiKey} disabled={isLoading} className='btn btn-outline-success fw-bold'>
                            {isLoading ? 'Generazione in corso...' : 'Genera nuova API-Key'}
                        </button>
                    </div>
                </div>
            ) : (
                <Navigate to="/login" />
            )}
        </>
    )
}

export default Dashboard