import {React} from 'react'
import Sidebar from './Sidebar';
import {Route, Routes} from 'react-router-dom';
import PageNotFound from '../pages/PageNotFound';
import HomeDoc from '../pages/HomeDoc';
import Authpage from '../pages/Authpage';
import Endpointspages from '../pages/Endpointspages';
import ConfigurazionePage from '../pages/ConfigurazionePage'
import SupportoPage from '../pages/SupportoPage';

const Documentation = () => {
    return (
        <div className="container mt-3">
            <div className="row">
                <div className="col-sm-2 text-start mt-2">
                    <Sidebar />
                </div>
                <div className='col-sm-9 mt-2 ps-5'>
                    <Routes>
                        <Route path='/' element={<HomeDoc />} />
                        <Route path='config' element={<ConfigurazionePage />}/>
                        <Route path='auth' element={<Authpage />}/>
                        <Route path='endpoints' element={<Endpointspages />} />
                        <Route path='support' element={<SupportoPage />}/>
                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default Documentation