import React from 'react'

const Widget = (props) => {
    return (
        <div className="col-sm-3 mt-2">
            <div className="card widget-flat">
                <div className="card-body">
                    <div className="float-end">
                        <i className="mdi mdi-account-multiple widget-icon"></i>
                    </div>
                    <h5 className="text-black fw-normal mt-0" title={props.title}>{props.title}</h5>
                    <h4 className="mt-3 mb-3 evidence_a cursor-p">{props.maxCall}</h4>
                    <p className="mb-0 text-muted">
                        <span className={`fw-bold me-2 fst-italic ${props.colore}`}><i className="mdi mdi-arrow-up-bold"></i>{props.uso}</span>
                        <span className="text-nowrap fst-italic">{props.text}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Widget