import React from 'react'

const BoxEndpoints = (props) => {
    return (
        <div className='container mx-2'>
            <h2 className='fw-bold'>{props.title}</h2>
            <div className='container mt-3'>
                <h5>{props.desc_b}</h5>
                <hr className='col-sm-9' style={{ margin: '5px 0' }} />
                <p className='w-75 fs-6 mt-2'>
                    <code>{props.endpoint}</code> <br />
                    <br />
                    {props.desc_d} <br />
                </p>
            </div>

            {/* se c'è un sotto endpoint alla si inizializza la variabile a y (vero) */}
            {props.isSub === "y" ? (
                <div className='container mt-3 ms-4'>
                    <h4 className='fw-bold'><span className='evidence'>-{'>'} </span>{props.sub_title}</h4>
                    <h5>{props.sub_desc_b}</h5>
                    <hr className='col-sm-9' style={{ margin: '5px 0' }} />
                    <p className='w-75 fs-6 mt-2'>
                        <code>{props.sub_endpoint}</code> <br />
                        <br />
                        {props.sub_desc_d} <br />
                    </p>
                </div>
            ) : (<></>)}

            {/* se c'è un ulteriore endpoint ancora sotto il sub di prima */}
            {/* se c'è un sotto endpoint alla si inizializza la variabile a yss (vero sotto sub) */}
            {props.isSottoSub === "yss" ? (
                <div className='container mt-3 ms-4'>
                    <h4 className='fw-bold'><span className='evidence'>-{'>'} </span>{props.sub_s_title}</h4>
                    <h5>{props.sub_s_desc_b}</h5>
                    <hr className='col-sm-9' style={{ margin: '5px 0' }} />
                    <p className='w-75 fs-6 mt-2'>
                        <code>{props.sub_s_endpoint}</code> <br />
                        <br />
                        {props.sub_s_desc_d} <br />
                    </p>
                </div>
            ) : (<></>)}

        </div>
    )
}

export default BoxEndpoints