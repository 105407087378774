import { React, useState } from 'react'

const apiUrl = process.env.REACT_APP_API_URL || 'https://api.territ.it';


const SupportoPage = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(apiUrl+`/support/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        console.log('Messaggio inviato con successo');
      } else {
        console.error("Errore nell'invio del messaggio");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='container mx-2'>
      <h2 className='fw-bold'>Supporto</h2>

      <div className='container mt-3'>
        <h5>
          Il nostro supporto ti offrità tutto ciò di cui
          hai bisogno per poter configurare l'API
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-2'>
          <br />
          Benvenuti nella nostra pagina di supporto dedicata a
          fornire assistenza e risolvere eventuali problemi o
          domande che possiate avere riguardo alla nostra
          piattaforma o ai nostri servizi. Siamo qui per
          aiutarvi a superare qualsiasi difficoltà e rendere
          la vostra esperienza il più piacevole possibile.
          <br />
        </p>
      </div>

      <div className='container mt-4'>
        <h5>
          Come possiamo aiutarvi
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-1'>
          <br />
          Siamo qui per offrirvi supporto completo,
          che includa risposte alle vostre domande
          più frequenti, guide dettagliate su come
          utilizzare le varie funzionalità della
          nostra piattaforma e assistenza personalizzata
          per risolvere problemi specifici che possiate
          incontrare.
          <br />
        </p>
      </div>

      <div className='container mt-4'>
        <h5>
          Contattaci
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-1'>
          <br />
          Non esitate a contattarci se non trovate
          le risposte di cui avete bisogno o se avete
          bisogno di assistenza immediata. Potete
          raggiungerci tramite il nostro modulo di
          contatto o utilizzando le informazioni di
          contatto fornite in basso.
          <br />
          <br />

          <div className='border rounded'>
            <form onSubmit={handleSubmit} className="container p-4">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email:</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">Messaggio:</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='text-center'>
                <button type="submit" className="btn btn_evidence text-white">Invia</button>
              </div>
            </form>
          </div>
        </p>
      </div>

      <div className='container mt-4'>
        <h5>
          Feedback
        </h5>
        <hr className='col-sm-10' style={{ margin: '5px 0' }} />
        <p className='w-75 fs-6 mt-1'>
          <br />
          Il vostro feedback è prezioso per noi.
          Se avete suggerimenti su come possiamo migliorare la
          nostra pagina di supporto o qualsiasi altro aspetto
          della nostra piattaforma, non esitate a condividerli
          con noi. Siamo sempre desiderosi di ascoltare e migliorare.
          <br /> <br />
          Grazie per aver scelto di utilizzare i nostri servizi.
          Siamo qui per voi e faremo del nostro meglio per garantire
          una risoluzione rapida e soddisfacente di qualsiasi problema
          possiate incontrare.
          <br />
        </p>
      </div>

    </div>
  )
}

export default SupportoPage