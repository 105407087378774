import React from 'react';
import logo from '.././assets/img/logo.webp';
import { Link } from 'react-router-dom';
import IubendaScriptLoader from './IubendaScriptLoader'; // Importa il componente per caricare lo script

const Footer = () => {
    return (
        <div className='container-fluid footer' style={{ backgroundColor: 'rgba(0%, 0%, 0%, 81%)' }}>
            <footer className="row row-cols-1 ps-3 row-cols-sm-2 row-cols-md-5 py-5 border-top ">
                
                <div className='col mb-3 ps-5 mt-5'>
                    <div className="ps-5">
                        <a href="/" className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
                            <img src={logo} alt='TerritAPI' className='bi me-2' width={150} height={50} />
                        </a>
                    </div>
                </div>

                <div className="col mb-3">
                    <h5 className='evidence fs-4 fw-bold'>Territ API</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2">
                            <a href="/register" className="vh nav-link p-0 text-white-50 ">
                                Registrati
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="/chisiamo" className="vh nav-link p-0 text-white-50 ">
                                Chi Siamo
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="/doc/supporto" className="vh nav-link p-0 text-white-50">
                                Supporto
                            </a>
                        </li> 
                    </ul>
                </div>

                <div className="col mb-3">
                    <h5 className='text-white fs-4 fw-bold'>API</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2">
                            <Link to='/doc' className='vh nav-link p-0 text-white-50'>
                                Documentazione
                            </Link>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="/doc/config" className="vh nav-link p-0 text-white-50">
                                Configurazione
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="/doc/endpoints" className="vh nav-link p-0 text-white-50">
                                Endpoint
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="col mb-3">
                    <h5 className='text-white fs-4 fw-bold'>Documentazioni Legali</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2">
                            <a href="https://www.iubenda.com/termini-e-condizioni/69769449" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" title="Termini e Condizioni">
                                Termini e Condizioni
                            </a>
                            <IubendaScriptLoader />
                        </li>
                        <li className="nav-item mb-2">
                            <a href="https://www.iubenda.com/privacy-policy/69769449" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" title="Privacy Policy">
                                Privacy Policy
                            </a>
                            <IubendaScriptLoader />
                        </li>
                        <li className="nav-item mb-2">
                            <a href="https://www.iubenda.com/privacy-policy/69769449/cookie-policy" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" title="Privacy Policy">
                                Cookie Policy
                            </a>
                            <IubendaScriptLoader />
                        </li>
                    </ul>
                </div>
            </footer>

            <div className='my-0 py-0 pb-1'>
                <p className="text-white text-center">
                    <span className='evidence fw-bold'>Territ API </span> 
                    ©<span>{new Date().getFullYear()}</span> <br />
                    Made By <span> </span>
                    <a className="vh link-offset-2 link-underline link-underline-opacity-0 text-danger" href='https://mattiavolpentesta.altervista.org'>Mattia Volpentesta</a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
