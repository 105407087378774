import React from 'react'
import {Link} from 'react-router-dom';

const Sidebar = () => {
    return (
        <div>
            <p style={{ margin: '5px 0' }}>
                <span className="h3 text-secondary-emphasis link-offset-2 link-underline link-underline-opacity-0 fs-4">Documentatione</span>
            </p>
            <hr className='col-sm-11' style={{ margin: '5px 0' }} />
            <p style={{ margin: '5px 0' }}>
                <Link to='config' className='vh text-secondary-emphasis link-offset-2 link-underline link-underline-opacity-0'>Configurazione</Link>
            </p>
            <hr className='col-sm-7' style={{ margin: '5px 0' }} />
            <p style={{ margin: '5px 0' }}>
                <Link to='auth' className='vh text-secondary-emphasis link-offset-2 link-underline link-underline-opacity-0 '>Autenticazione</Link>
            </p>
            <hr className='col-sm-7' style={{ margin: '5px 0' }} />
            <p style={{ margin: '5px 0' }}>
                <Link to='endpoints' className='vh text-secondary-emphasis link-offset-2 link-underline link-underline-opacity-0'>Endpoints</Link>
            </p>
            <hr className='col-sm-7' style={{ margin: '5px 0' }} />
            <p style={{ margin: '5px 0' }}>
                <Link to='support' className='vh text-secondary-emphasis link-offset-2 link-underline link-underline-opacity-0'>Supporto</Link>
            </p>
        </div>
    )
}

export default Sidebar