import { React, useState } from 'react';
import logo from '../assets/img/logo.webp';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || 'https://api.territ.it';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function handleSubmit(event) {
        event.preventDefault();
        axios.post(apiUrl + `/auth/login`, { username, password })
            .then(res => {
                if (res.data && res.data.message === 'Login effettuato con successo') {
                    const token = res.data.auth.chiave;
                    const id = res.data.info.userId;
                    setIsLoggedIn(true);
                    localStorage.setItem('isLogin', 'true');
                    localStorage.setItem('userId', id);
                    localStorage.setItem('tokenAuth', token);
                } else {
                    setErrorMessage('Password errata. Riprova');
                    console.error('Login non riuscito');
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
        setErrorMessage('');
    };

    return (
        <>
            {isLoggedIn ? (
                <Navigate to="/area-privata" />
            ) : (
                <div className="d-flex justify-content-center align-items-center min-vh-100" style={{backgroundColor: 'rgba(0%, 0%, 0%, 81%)',  overflow: 'hidden' }}>
                    <div className="p-4" style={{ width: '100%', maxWidth: '400px', borderRadius: '8px' }}>
                        <div className="text-center mb-4">
                            <a href='/'>
                                <img src={logo} alt="territ.it" width="150" />
                            </a>
                        </div>

                        {errorMessage && (
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {errorMessage}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <h3 className="text-center text-white">Accedi</h3>
                                <p className="text-center text-white-50">
                                    Nuovo su territ ? <Link to='/register' className='evidence_a'>Crea un account qui.</Link>
                                </p>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="username" className="form-label text-white">Username <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="username" id="username" required 
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="password" className="form-label text-white">Password <span className="text-danger">*</span></label>
                                <input type="password" className="form-control" name="password" id="password" required 
                                    onChange={handlePassword}
                                />
                            </div>

                            <div className="d-grid">
                                <button className="btn btn_evidence btn-lg text-white" type="submit">Accedi</button>
                            </div>
                        </form>

                        <div className="text-center mt-3">
                            <p className="text-white-50">&copy; territ.it {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Login;
