import {React, useState, useEffect } from 'react'
import logo from '.././assets/img/logo.webp';
import {Link, useLocation} from 'react-router-dom';
import axios from 'axios';

//const HOST_API = process.env.API_DOM;
const apiUrl = process.env.REACT_APP_API_URL || 'https://api.territ.it';


const Navbar = () => {
    const location = useLocation();
    const [, setIsActive] = useState(false);
    const [versione, setVersione] = useState(null);
  
    useEffect(() => {
        // Verifica se la route attuale è /area-privata e imposta isActive di conseguenza
        setIsActive(location.pathname === '/area-privata');
        
        //prende la versione dell'API tramite endpoint
        //axios.get(`${HOST_API}/info/versione`)
        axios.get(apiUrl+'/info/versione')
        .then(response => {
            setVersione(response.data.info.versione);
            console.log(response);
        })
        .catch(error => {
            console.log('Errore nel recupero della versione');
            console.error('Errore nel recupero della versione:', error);
            console.log('Errore nel recupero della versione');
        })

    }, [location]);
  

    let [isLoggedIn, setIsLoggedIn] = useState(''); 

    isLoggedIn = localStorage.getItem('isLogin');
    //setIsLoggedIn(local_login)

    const handleLogout = () => {
        // Rimuovi le variabili dal local storage
        localStorage.removeItem('tokenAuth'); // Esempio: token di autenticazione
        localStorage.removeItem('isLogin'); // Esempio: informazioni sull'utente
        //localStorage.removeItem('username')
        // Imposta lo stato di accesso a false
        setIsLoggedIn(false);
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg " style={{backgroundColor: 'rgba(0%, 0%, 0%, 81%)' }}>
                <div className="container-fluid text-white">
                    <a className="navbar-brand mx-5" href="/">
                        <img src={logo} alt="TerritAPI" width="150" height="50" />
                        <a className='text-white fs-6 version_api' href="/">v{versione} </a>
                    </a>
                    <button className="navbar-toggler bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mx-5 px-1">
                            {/*<li className="nav-item">
                                <Link to='/' className='nav-link text-white'>Home</Link>
                            </li>*/}
                            <li className="nav-item">
                                <Link to='/doc' className='nav-link text-white'>Documentatione</Link>
                            </li>
                            <li className="nav-item">
                                <Link to='https://bytebrain.altervista.org/category/territ-api/' target='__blank' className='nav-link text-white'>News</Link>
                            </li>
                            {isLoggedIn ? (
                                <div>
                                    <Link to='/area-privata' className='sh nav-link evidence fw-bold'>Dashboard</Link>
                                </div>
                            ):(<></>)} 
                            {isLoggedIn ? (
                                <div>
                                    <Link to='/login' onClick={handleLogout} className='sh nav-link text-white fw-bold'>Logout</Link>
                                </div>   
                            ) : (
                                <li className="nav-item">
                                    <Link to='/login' className='sh nav-link text-white fw-bold'>Login</Link>
                                </li>
                            )}
                        </ul>                                     
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar