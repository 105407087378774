import React from 'react';

const Alert = ({ message, show }) => {
  return (
    show && (
        <div className="d-flex justify-content-center align-items-center">
            <div className="alert alert-success w-25 text-center" role="alert">
                {message}
            </div>
        </div>
    )
  );
};

export default Alert;