import React from 'react'
import BoxEndpoints from '../components/BoxEndpoints'

const Endpointspages = () => {
  return (
    <div>
      {/* Endpoint Regioni */}
      <BoxEndpoints 
        title = 'Elenco delle Regioni'
        desc_b = 'Ottieni un elenco completo delle regioni Italiane'
        endpoint = '/regione'
        desc_d = "Questo endpoint fornisce un elenco completo delle regiorni Italiane. Ogni voce nell'elenco include dettagli come il nome della regione e, opzionalmente, ulteriori informazioni quali il codice identificativo, la popolazione, e altre caratteristiche rilevanti."
      />
      {/* Endpoint Province */}
      <BoxEndpoints 
        title = 'Elenco delle Province'
        desc_b = "Ottieni un elenco di tutte le province Italiane."
        endpoint = '/province'
        desc_d = "Questo endpoint restituisce un elenco completo delle province presenti in un determinato paese. Ogni voce nell'elenco fornisce informazioni dettagliate come il nome della provincia, il codice identificativo, la popolazione, e altre proprietà pertinenti."

        /* sub pronvince/:regione */      
        isSub = "y"
        sub_title = "Province per Regione"
        sub_desc_b = "Ottieni un elenco di province di una regione specifica."
        sub_endpoint = "/province/:regione"
        sub_desc_d = "Questo endpoint fornisce un elenco di province che appartengono a una regione specifica. Ogni voce nell'elenco include dettagli come il nome della provincia, il codice identificativo, la popolazione, e altre informazioni rilevanti sulla provincia."
      />

      {/* Endpoint Comuni */}
      <BoxEndpoints
        title = 'Elenco dei Comuni'
        desc_b = "Ottieni un elenco completo dei comuni all'interno di un paese."
        endpoint = '/comuni'
        desc_d = "Questo endpoint restituisce un elenco completo di tutti i comuni presenti in un determinato paese. Ogni voce nell'elenco include informazioni dettagliate come il nome del comune, il codice identificativo, la popolazione, e altre caratteristiche rilevanti."
      
        /* sub comuni/:regione */      
        isSub = "y"
        sub_title = "Comuni per Regione"
        sub_desc_b = "Ottieni un elenco di comuni che appartengono a una regione specifica."
        sub_endpoint = "/comuni/:regione"
        sub_desc_d = "Questo endpoint fornisce un elenco di comuni che appartengono a una regione specifica. Ogni voce nell'elenco include dettagli come il nome del comune, il codice identificativo, la popolazione, e altre informazioni rilevanti."
        
        /* sub comuni/provincia/:provincia */
        isSottoSub = "yss"
        sub_s_title = "Comuni per Provincia"
        sub_s_desc_b = " Ottieni un elenco di comuni che fanno parte di una provincia specifica."
        sub_s_endpoint = "/comuni/provincia/:provincia"
        sub_s_desc_d = "Questo endpoint restituisce un elenco di comuni che fanno parte di una provincia specifica. Ogni voce nell'elenco include dettagli come il nome del comune, il codice identificativo, la popolazione, e altre informazioni rilevanti sulla provincia."
      />
    </div>
  )
}

export default Endpointspages