import { React, useState } from 'react'
import logo from '../assets/img/logo.webp';
import { Link, Navigate } from 'react-router-dom'
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || 'https://api.territ.it';

const Register = () => {

    //attributi per la registrazione 
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [abbonamento, setAbbonamento] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    //connessione all'API di registrazione del backend dove risiede tutta l'API
    async function handleSubmit(event) {
        event.preventDefault();
        try {
            const res = await axios.post(apiUrl + `/auth/register`, {
                nome,
                cognome,
                abbonamento,
                username,
                password
            });
            console.log(res.data);

            // Verifica se la risposta contiene un campo di dati valido
            if (res.data && res.data.message === 'Utente registrato con successo') {
                // Se il login è riuscito, imposta lo stato di autenticazione su true
                const token = res.data.auth.chiave;
                setIsLoggedIn(true);
                localStorage.setItem('isLogin', 'true');
                localStorage.setItem('tokenAuth', token)
            } else {
                setErrorMessage('Non è stato possibile effettuare la registrazione. Riprova');
                console.error('Registrazione non riuscita');
            }
        } catch (error) {
            console.error('Errore durante la registrazione:', error);
        }
    }


    return (
        <>
            {/* Utilizza la logica di rendering condizionale per mostrare il componente protetto dopo la registrazione */}
            {isLoggedIn ? (
                <Navigate to="/area-privata" />
            ) : (
                <div className="d-flex justify-content-center align-items-center min-vh-100" style={{ backgroundColor: 'rgba(0%, 0%, 0%, 81%)', overflow: 'hidden' }}>
                    <div className="p-4" style={{ width: '100%', maxWidth: '400px', borderRadius: '8px' }}>
                        <div className="text-center mb-4">
                            <a href='/'>
                                <img src={logo} alt="territ.it" width="150" />
                            </a>
                        </div>

                        {errorMessage && (
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {errorMessage}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        )}

                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <h3 className="text-center text-white">Crea Nuovo Account</h3>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="nome" className="form-label text-white">Nome <span className="text-danger">*</span></label>
                                {/* Input Nome */}
                                <input type="text" className="form-control" name="nome" id="nome" required 
                                        onChange={(e) => setNome(e.target.value)}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="cognome" className="form-label text-white">Cognome <span className="text-danger">*</span></label>
                                {/* Input Cognome */}
                                <input type="text" className="form-control" name="cognome" id="cognome" required 
                                        onChange={(e) => setCognome(e.target.value)}
                                    />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="username" className="form-label text-white">Username <span className="text-danger">*</span></label>
                                {/* Input Username */}
                                <input type="text" className="form-control" name="username" id="username" required 
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="password" className="form-label text-white">Password <span className="text-danger">*</span></label>
                                {/* Input Password */}
                                <input type="password" className="form-control" name="password" id="password" required 
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="abbonamento" className="form-label text-white">Piano abbonamento <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    {/* Input abbonamento */}
                                    <select onChange={(e) => setAbbonamento(e.target.value)} className="form-select" aria-label="Default select example" name="abbonamento" id="abbonamento" required>
                                        <option defaultValue="1">Seleziona un piano d'abbonamento</option>
                                        <option value="1">Gratis</option>
                                    </select>
                                </div>
                            </div>

                            <div className="d-grid">
                                <button className="btn btn_evidence btn-lg text-white" type="submit">Crea Account</button>
                            </div>
                        </form>

                        <div className="text-center mt-3">
                            <p className="text-center text-white-50">
                                Hai già un account con Territ ? <Link to='/login' className='evidence_a'>Accedi qui.</Link>
                            </p>
                            <p className="text-white-50">&copy; territ.it {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Register